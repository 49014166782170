import React, { Component } from "react";

class SubscribeThanks extends Component {
  render() {
    return (
<div className="bg-white">
  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
      Thanks for subscribing&nbsp;
      <br className="hidden sm:inline" />
      <span className="text-indigo-600">We'll send you updates whenever we can.</span>
    </h2>
  </div>
</div>


    );
  }
}

export default SubscribeThanks;
